<template>
  <div class="pageContol formCom">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="$router.back()">消息管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">公告推送</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">发布公告</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="10rem" class>
              <el-form-item label="标题名称" prop="noticeTitle" class="form-item">
                <el-input
                  v-model="ruleForm.noticeTitle"
                  maxlength="50"
                  :show-word-limit="true"
                  :disabled="ruleForm.id ? true : false"
                  placeholder="请输入标题名称"
                />
              </el-form-item>
              <el-form-item label="公告内容" prop="noticeDetail" class="form-item">
                <div ref="editor" class="editor" style="width:100%" />
              </el-form-item>
              <el-form-item label="上传文件" prop="attachmentUrl">
                <div class="seeStyle">
                  <el-upload
                    v-model="ruleForm.fileName"
                    v-if="!ruleForm.fileName"
                    class="senPDf"
                    :action="actionUrl"
                    :on-error="handleError"
                    :on-success="handleSuccess"
                    :on-change="uploadChange"
                    :show-file-list="false"
                    :auto-upload="false"
                  >
                    <el-button size="small" class="bgc-bv">上传PDF文件</el-button>
                  </el-upload>
                  <span style="color:#f46173" v-else>
                    {{ ruleForm.fileName }}
                    <a @click="reomveExl" style="color: #5bb5ff">删除</a>
                  </span>
                  <span style="color:#f46173" v-if="seeUrl">
                    <a @click="seeFun" style="color: #5bb5ff;margin-left:20px">预览</a>
                  </span>
                </div>
              </el-form-item>
           
         
              <el-form-item label="推送单位" prop="childCompIds" class="form-item">
                <el-cascader
                  :options="companyList"
                  :props="propsarea"
                  clearable
                  filterable
                  v-model="ruleForm.childCompIds"
                  placeholder="请选择推送单位"
                  style="width:100%"
                ></el-cascader>
              </el-form-item>
            </el-form>

            <div class="pageForm-btns">
              <el-button @click="onCancel" class="bgc-bv">取消</el-button>
              <el-button class="bgc-bv" @click="submit">确定</el-button>
            </div>
          </div>
        </div>
        <el-dialog
          title="pdf预览"
          :visible.sync="dialogCert"
          width="50%"
          center
          :before-close="closeDownloadCert"
        >
          <div id="pdf-cert" style="height: 800px"></div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>
import Form from "@/mixins/Form";
import E from "wangeditor";

import pdf from "pdfobject";
let editor = {};
let updataTimer = null;
export default {
  name: "AnnouncementPushEditJG",
  components: {},
  mixins: [Form],
  data() {
    return {
      dialogCert: false,
      seeUrl: "",
      eWidth: "",
      companyList: [], //推送单位列表
      ruleForm: {
        updateId: "",
        noticeTitle: "", //标题
        noticeDetail: "", //富文本
        attachmentUrl: "", //附件地址
        childCompIds: [],
      },

      propsarea: {
        //推送单位的值
        value: "compId",
        label: "compName",
        emitPath: false,
        checkStrictly: true,
        multiple: true
      },
     
      rules: {
        noticeTitle: [
          { required: true, message: "请输入标题名称", trigger: "blur" }
        ],
        noticeDetail: [
          { required: true, message: "请输入公告内容", trigger: "change" }
        ],
        attachmentUrl: [
          { required: false, message: "请上传PDF文件", trigger: "change" }
        ],
        childCompIds: [
          { required: true, message: "请选择行政区划", trigger: "change" }
        ],
       
      }
    };
  },
  watch: {
    "ruleForm.noticeDetail": function(val) {
      if (val) {
        this.$refs["ruleForm"].clearValidate("noticeDetail");
      }
    },
    "ruleForm.attachmentUrl": function(val) {
      if (val) {
        this.$refs["ruleForm"].clearValidate(["attachmentUrl"]);
      }
    }
  },
  created() {},
  mounted() {
    this.editorInit();
    const eWidth = this.$refs.editor.clientWidth;
    this.eWidth = eWidth;
    this.getareatree();
    if (this.$route.query.id == "0") {
      this.ruleForm.updateId = "";
      this.$refs["ruleForm"].resetFields();
    } else {
      this.ruleForm.updateId = this.$route.query.id;
      this.$refs["ruleForm"].resetFields();
      this.getDetail(this.ruleForm.updateId);
    }
  },
  methods: {
    onCancel() {
      this.$router.push("/web/AnnouncementPushJG");
    },
    seeFun() {
      this.dialogCert = true;
      this.$nextTick(() => {
        pdf.embed(this.seeUrl, "#pdf-cert");
      });
    },
 
    //获取推送单位
    getareatree() {
      this.$post("/sys/company/getChildComp").then(ret => {
        this.companyList = ret.data;
       
      });
    },
    //提交
    uploadChange(file) {
      let size = file.size / 1024 / 1024;
      // let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "PDF";
      const isxlsx = extension === "pdf";

      if (!isXLSX && !isxlsx) {
        this.$message.error("只能上传后缀是.pdf文件");
        return;
      }
      if (size > 10) {
        this.$message.error("文件大小不能超过1M");
        return;
      }
      this.ruleForm.fileName = file.name;
      let formData = new FormData();
      formData.append("folder ", "QUESTION");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);
      this.$Postformat("/sys/upload", formData)
        .then(result => {
          this.ruleForm.attachmentUrl = result.data.fileKey;
          this.seeUrl = result.data.fileURL;
          this.$refs["ruleForm"].clearValidate("fileName");
        })
        .catch(() => {
          setTimeout(() => {
            this.$message({
              type: "warning",
              message: "保存失败"
            });
          }, 300);
        });
      this.$forceUpdate();
    },
    reomveExl() {
      this.ruleForm.fileName = "";
      this.ruleForm.excelUrl = "";
      this.actionUrl = "";
      this.$forceUpdate();
    },
    getLast(data) {
      let arr = [];
      data.forEach((el, i) => {
        if (el.length > 0) {
          let num = el.length;
          arr.push(el[num - 1]);
        } else {
          arr.push("");
        }
      });
      return arr;
    },
    submit() {
      const el = this.$refs["ruleForm"];
      el.validate(valid => {
        if (valid) {
          //行政区划处理
          let url = "";
          if (this.ruleForm.updateId == "") {
            url = "/notice/insertCompNotice";
          } else {
            url = "/notice/modifyNotice";
          }
          this.$post(url, { ...this.ruleForm }).then(result => {
            if (result.status === "0") {
              this.$message({
                type: "success",
                message: "发布成功"
              });
              this.$router.push({
                path: "/web/AnnouncementPushJG",
                query: {
                  refresh: true
                }
              });
            }
          });
        } else {
          this.$message({
            type: "warning",
            message: "请输入必填项"
          });
        }
      });
    },
    getDetail(noticeId) {
      this.$post("/notice/getNoticeInfo", { noticeId: noticeId }).then(
        result => {
          if (result.status === "0") {
            let data = result.data;
        
            this.ruleForm.noticeDetail = data.noticeDetail;
            this.ruleForm.noticeId = data.noticeId;
            this.ruleForm.noticeTitle = data.noticeTitle;
            this.ruleForm.noticeType = data.noticeType;
            editor.txt.html(data.noticeDetail);
            this.seeUrl = data.previewUrl;

            this.ruleForm.childCompIds=[];
            for(let i in result.data.sendComps){
              this.ruleForm.childCompIds.push(result.data.sendComps[i].id)
            }
          }
        }
      );
    },
    // 富文本
    editorInit() {
      editor = new E(this.$refs.editor);
      editor.config.menus = [
        "head", // 标题
        "bold", // 粗体
        "fontSize", // 字号
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        "list", // 列表
        "justify", // 对齐方式
        "quote", // 引用
        "emoticon", // 表情
        "image", // 插入图片
        "table", // 表格
        "undo", // 撤销
        "redo" // 重复
      ];
      editor.config.zIndex = 100;
      editor.config.uploadImgShowBase64 = true;
      editor.config.uploadImgMaxSize = 1 * 1024 * 1024;
      editor.config.pasteFilterStyle = "text";
      editor.config.pasteIgnoreImg = true;

      editor.config.onchange = html => {
        this.ruleForm.noticeDetail = html;
      };
      editor.config.pasteTextHandle = function(content) {
        return content + "<p></p>";
        // content 即粘贴过来的内容（html 或 纯文本），可进行自定义处理然后返回
      };
      editor.create();
      const eWidth = this.$refs.editor.clientWidth;
      this.eWidth = eWidth;
    }
    /**
     * 拖动按钮
     */
  }
  // beforeDestroy: function() {
  //   if (updataTimer) {
  //     clearTimeout(updataTimer);
  //   }
  // }
};
</script>
<style lang="less">
.seeStyle {
  display: flex;
  flex-direction: row;
}
.pageForm-btns {
  padding: 15px 0;
  text-align: center;
  .el-button {
    width: 10rem;
    span {
      min-width: 4em;
    }
  }
}
.senPDf {
  height: 40px;
  .el-upload {
    height: 40px !important;
    border: none !important;
  }
}

.addtestPaper {
  .el-input--small .el-input__inner {
    height: 32px;
    line-height: 32px;
  }
}
.check-all-btn {
  font-size: 22px;
  line-height: inherit;
  color: #409eff;
  cursor: pointer;
}
.check-all-btn:hover {
  color: #66b1ff;
}
</style>